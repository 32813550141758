/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateTiVotes = /* GraphQL */ `
  subscription OnCreateTiVotes($Id: String, $VoteId: String) {
    onCreateTiVotes(Id: $Id, VoteId: $VoteId) {
      Id
      VoteId
      Vote
      __typename
    }
  }
`;
export const onUpdateTiVotes = /* GraphQL */ `
  subscription OnUpdateTiVotes($Id: String, $VoteId: String) {
    onUpdateTiVotes(Id: $Id, VoteId: $VoteId) {
      Id
      VoteId
      Vote
      __typename
    }
  }
`;
export const onDeleteTiVotes = /* GraphQL */ `
  subscription OnDeleteTiVotes($Id: String, $VoteId: String) {
    onDeleteTiVotes(Id: $Id, VoteId: $VoteId) {
      Id
      VoteId
      Vote
      __typename
    }
  }
`;
